import React from 'react';
import { Redirect } from 'react-router-dom';
import "./Maintenance.scss";

const MaintenancePage = () => {
 const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === 'true';
 if(!isMaintenanceMode) {
    return <Redirect to="/" />
 }
  return (
    <div className='maintenance-container'>
        <img src='/img/maintenance-logo.png' alt='maintenance'/>
      <h1>We are undergoing routine maintenance.</h1>
      <p>Please check back after 3:30 AM EDT on 30 September 2024.</p>
    </div>
  );
};

export default MaintenancePage;
